import React, { useState, useEffect, useRef, useContext } from "react";
import { graphql, Script, withPrefix } from "gatsby";
import loadable from "@loadable/component";
import _ from "lodash";
import parse from "html-react-parser";
import { IntlProvider } from "react-intl";
import Layout from "../components/layout";
import HeaderScripts from "../components/HeadScripts";
import GoogleAds, { LoadInfSlot } from "../components/google-ads";
import SeoStructure from "../components/seo-structure";

import useTaboola from "../hooks/useTaboola";
import {
  isBrowser,
  isMobilePredicate,
  DebugModeLog,
  toggleElement,
} from "../utils";
import { defaultLanguage } from "../i18n";
import LocalesContext from "../context/LocalesContext";
import TrackingContext from "../context/TrackingContext";
import { Loader } from "../components/loader";
import Vidazoo from "../components/vendor/Vidazoo";

export function Head({ data, pageContext }) {
  const post = data.posts.nodes[0];
  const canonicalSlug = `/${pageContext.slug}`;
  const excerptWithoutTags = post.excerpt.replace(/(<([^>]+)>)/gi, "");
  return (
    <>
      <HeaderScripts />
      <Script
        async
        src={process.env.GATSBY_PUBSTACK_TAG}
        type="text/javascript"
      />
      <SeoStructure
        title={post.title}
        description={excerptWithoutTags ? excerptWithoutTags : ""}
        slug={`${pageContext.locale}${canonicalSlug}`}
      >
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            "@id": `${!post?.id ? "" : post?.id}`,
            datePublished: `${new Date(post?.date)}`,
            dateModified: `${new Date(post?.modified)}`,
            headline: `${!post?.title ? "" : post?.title}`,
            author: {
              "@type": "Person",
              name: `${
                !post?.author?.node?.name ? "" : post?.author?.node?.name
              }`,
            },
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": `${!post?.slug ? "" : post?.slug}`,
            },
            publisher: {
              "@type": "Organization",
              name: `${
                !process.env.GATSBY_SITE_NAME
                  ? ""
                  : process.env.GATSBY_SITE_NAME
              }`,
              logo: {
                "@type": "ImageObject",
                url: "",
              },
            },
            image: {
              "@type": "ImageObject",
              url: `${
                !post?.featuredImage?.node?.link
                  ? ""
                  : post?.featuredImage?.node?.link
              }`,
              width: `${
                !post?.featuredImage?.node?.width
                  ? ""
                  : post?.featuredImage?.node?.width
              }`,
              height: `${
                !post?.featuredImage?.node?.height
                  ? ""
                  : post?.featuredImage?.node?.height
              }`,
            },
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: `${post.title}`,
              },
            ],
          })}
        </script>
      </SeoStructure>
    </>
  );
}

// Counter for parallax IDs
let currentParallaxAdIndex = 0;

// Loadable imports
const TaboolaWidget = loadable(() =>
  import("../components/vendor/TaboolaWidget")
);
const OutbrainWidget = loadable(() =>
  import("../components/vendor/OutbrainWidget")
);

function BlogPost({ data, pageContext }) {
  const post = data.posts.nodes[0];
  const allSections = !!post.nextPageTC ? post.nextPageTC : [];
  const [utm, setUtm] = useState("");
  // Tells React that the page is ready, needs to be used for conditional elements
  const [pageReady, setPageReady] = useState(null);
  let [prebidTimeout, setPrebidTimeout] = useState(2700);

  const [isMobile, setIsMobile] = useState(false);
  const [windowOrigin, setWindowOrigin] = useState("");

  const [incrementTimeout, setIncrementTimeout] = useState(false);
  const [triggeredTimeout, setTriggeredTimeout] = useState(false);

  const [currentListIndex, setCurrentListIndex] = useState(0);
  const [scrolledToFirstSection, setScrolledToFirstSection] = useState(false);

  const currentListIndexRef = useRef(0);
  currentListIndexRef.current = currentListIndex;

  // State to trigger load more
  const [loadMore, setLoadMore] = useState(false);
  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(true);
  //Set a ref for the loading div
  const loadRef = useRef();

  const { setupTaboola } = useTaboola(1);

  // Taboola
  const showTaboola =
    isBrowser &&
    utm &&
    !(
      utm.includes("ob-") ||
      utm.includes("Zemanta") ||
      utm.includes("nosource")
    );
  let taboolaPlacement = "Taboola Feed Desktop";
  let taboolaContainer = "TaboolaFeedDesktop";
  if (isMobile) {
    taboolaPlacement = "Taboola Feed Mobile";
    taboolaContainer = "TaboolaFeedMobile";
  }

  // Outbrain
  const showOutbrain =
    isBrowser &&
    utm &&
    !utm.includes("nosource") &&
    (utm.includes("ob-") || utm.includes("Zemanta"));
  let outbrainID = "CR_2";
  if (isMobile) {
    outbrainID = "AR_2";
  }
  const pageURL =
    process.env.GATSBY_MULTILINGUAL === "true"
      ? `${process.env.GATSBY_SITE_URL}/${pageContext.locale}/${pageContext.slug}/`
      : `${process.env.GATSBY_SITE_URL}/${pageContext.slug}/`;

  let parallaxAdIndex = 0;

  const { utm_source, abtest } = useContext(TrackingContext);

  let isFacebookTemplate;
  if (
    (utm_source?.toLowerCase().startsWith("fb-") && isMobile) ||
    (utm_source?.toLowerCase().startsWith("twtr_") && !isMobile)
  ) {
    isFacebookTemplate = true;
  } else if (
    (utm_source.toLowerCase().startsWith("mic_") && !isMobile) ||
    (utm_source.toLowerCase().startsWith("light_") && !isMobile)
  ) {
    isFacebookTemplate = true;
  } else {
    isFacebookTemplate = false;
  }

  // Setup Taboola Web Push
  useEffect(() => {
    setupTaboola();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle intersection with load more div
  const handleObserver = (entities) => {
    const target = entities[0];

    if (target.isIntersecting) {
      setLoadMore(true);
    }
  };

  // Initialize the intersection observer API
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "10px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(handleObserver, options);

    if (loadRef.current) {
      observer.observe(loadRef.current);
    }

    // Set utm source in state
    setUtm(window.localStorage.getItem("utm_source"));

    toggleElement("#main-footer", "hide");

    setPageReady(true);
  }, []);

  // Handle loading more articles
  useEffect(() => {
    const isMobile = isMobilePredicate();

    if (!hasMore) {
      toggleElement("#main-footer", "show");
    }

    if (loadMore && hasMore) {
      setCurrentListIndex((prevIndex) => {
        if (incrementTimeout && prevIndex > 1 && prevIndex % 3 === 0) {
          setPrebidTimeout(Math.trunc(prebidTimeout * 1.1));
          DebugModeLog("Prebid timeout: " + prebidTimeout);
        } else {
          if (!triggeredTimeout) {
            setTimeout(() => {
              DebugModeLog("Triggered timeout");
              setIncrementTimeout(true);
            }, 2000);

            setTriggeredTimeout(true);
          }
        }
        if (isMobile) {
          LoadInfSlot(
            currentListIndexRef.current,
            `MPU_Parallax_${prevIndex + 1 + currentParallaxAdIndex}`,
            "inf",
            pageContext.locale,
            utm,
            prebidTimeout
          );
          LoadInfSlot(
            currentListIndexRef.current,
            `MPU_Parallax_${prevIndex + 1 + 1 + currentParallaxAdIndex}`,
            "inf",
            pageContext.locale,
            utm,
            prebidTimeout
          );
        } else {
          LoadInfSlot(
            currentListIndexRef.current,
            `LeaderDesktopInfinite_${prevIndex + 1}`,
            "inf",
            pageContext.locale,
            utm,
            prebidTimeout
          );

          // LoadInfSlot(
          //   currentListIndex,
          //   `LeaderDesktopInfinite_${
          //     prevIndex + 1 + 1 + currentParallaxAdIndex
          //   }`
          // );
        }

        currentParallaxAdIndex++;

        if (currentListIndexRef.current === 1) {
          setScrolledToFirstSection(true);
        }

        if (currentListIndexRef.current === 2) {
          var fbVidazooContainer = document.getElementById(
            "fb-vidazoo-container"
          );
          if (fbVidazooContainer) {
            fbVidazooContainer.style.display = "block";
          }
        }

        return prevIndex + 1;
      });
      setLoadMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMore, hasMore]);

  //Check if there is more
  useEffect(() => {
    const isMore = currentListIndex < allSections.length;

    setHasMore(isMore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentListIndex]);

  useEffect(() => {
    const isMobile = isMobilePredicate();

    setIsMobile(isMobile);
    setWindowOrigin(window.location.origin);
  }, []);

  const setupOptioin = (index) => {
    return {
      replace: (node) => {
        if (!isBrowser()) {
          return;
        }

        if (!node.attribs) {
          return;
        }

        if (node.attribs && node.attribs.src) {
          return (
            <>
              {pageReady && index === 0 && isMobile && !isFacebookTemplate && (
                <div className="code-block code-block--banner">
                  <div className="divider">
                    <span>Advertisement</span>
                  </div>
                  <div id="LeaderBelowTitle" className="ad-container" />
                </div>
              )}

              {pageReady && index > 0 && isMobile && (
                <div
                  className="center code-block--parallax"
                  style={{ minWidth: "300px" }}
                >
                  <div className="divider">
                    <span>Advertisement</span>
                  </div>
                  <adk-parallax
                    id={`MPU_Parallax_${index + parallaxAdIndex}`}
                    className="ad-container"
                  />
                </div>
              )}

              {pageReady && (
                <img
                  src={node.attribs.src}
                  alt=""
                  loading={index === 0 ? "eager" : "lazy"}
                  width={node.attribs.width}
                  height={node.attribs.height}
                />
              )}

              {pageReady && index > 0 && !isMobile && (
                <div className="code-block code-block--banner">
                  <div className="divider">
                    <span>Advertisement</span>
                  </div>
                  <div
                    id={`LeaderDesktopInfinite_${index}`}
                    className="ad-container"
                  />
                </div>
              )}

              {pageReady && index === 0 && isMobile && !isFacebookTemplate && (
                <div className="code-block code-block--banner">
                  <div className="divider">
                    <span>Advertisement</span>
                  </div>
                  <div id="MPUTopRight" className="ad-container" />
                </div>
              )}
            </>
          );
        }

        return;
      },
    };
  };

  if (_.isEmpty(post)) return null;

  const locale =
    pageContext.locale !== defaultLanguage
      ? `/${pageContext.locale}`
      : `/${defaultLanguage}`;

  let videoPlayer;
  if (pageReady && !isFacebookTemplate) {
    videoPlayer = <Vidazoo vidazooId="647870b8e6c93f675397f31e" utm={utm} />;
  }

  return (
    <>
      {isMobile && (
        /* Setup Parallax */
        <Script src={withPrefix("parallax.js")} />
      )}
      <GoogleAds
        type="inf"
        page={pageContext.page + 1}
        lang={pageContext.locale}
        abtest={abtest}
        utm_source={utm}
      />
      <article className="post post--inf">
        <div className="post-main">
          <header className="post-header">
            <h1 className="entry-title">{post.title}</h1>
            <div className="post-meta">
              <div className="post-author-name">
                <div className="author-by">By</div>
                <span>{post.author.node.name}</span>
                <div className="author-line"> - </div>
              </div>
              <div className="post-date">{post.date}</div>
            </div>
          </header>

          <div className="post__video-player">{videoPlayer}</div>

          {
            // Vidazoo player for fb template
            pageReady && isFacebookTemplate && (
              <div id="fb-vidazoo-container" style={{ display: "none" }}>
                <Vidazoo vidazooId="647870b8e6c93f675397f31e" utm={utm} />
              </div>
            )
          }

          {pageReady && !isMobile && !isFacebookTemplate && (
            <div className="code-block code-block--banner">
              <div className="divider">
                <span>Advertisement</span>
              </div>
              <div id="LeaderBelowTitle" className="ad-container"></div>
            </div>
          )}

          <div className="post-content post-content--inf">
            {allSections.map((article, index) => {
              if (index > 1) {
                ++parallaxAdIndex;
              }

              return (
                <div
                  key={index}
                  className={`section`}
                  data-url={`${windowOrigin}${
                    process.env.GATSBY_MULTILINGUAL === "true" ? locale : ""
                  }/${pageContext.slug}/${
                    index + 1 === 1 ? "" : index + 1 + "/"
                  }`}
                  style={{
                    display: index > currentListIndex ? "none" : "block",
                  }}
                >
                  {parse(article, setupOptioin(index))}

                  {pageReady && index > 0 && isMobile && (
                    <div
                      className="code-block--parallax"
                      style={{ minWidth: "300px" }}
                    >
                      <div className="divider">
                        <span>Advertisement</span>
                      </div>
                      <adk-parallax
                        id={`MPU_Parallax_${index + 1 + parallaxAdIndex}`}
                        className="ad-container"
                      />
                    </div>
                  )}

                  {pageReady &&
                    index === 0 &&
                    isMobile &&
                    !isFacebookTemplate && (
                      <div className="code-block code-block--banner">
                        <div className="divider">
                          <span>Advertisement</span>
                        </div>
                        <div id="MPUCenterRight" className="ad-container"></div>
                      </div>
                    )}

                  {pageReady && index === 0 && !isMobile && (
                    <div className="code-block code-block--banner">
                      <div className="divider">
                        <span>Advertisement</span>
                      </div>
                      <div
                        id="LeaderDesktopInfinite"
                        className="ad-container"
                      ></div>
                    </div>
                  )}

                  {/* {index > 0 && !isMobile && (
                    <div className="code-block code-block--banner">
                      <div className="divider">
                        <span>Advertisement</span>
                      </div>
                      <div 
                        id={`LeaderDesktopInfinite_${
                          index + 1 + parallaxAdIndex
                        }`}
                        className="ad-container" 
                      />
                    </div>
                  )} */}
                </div>
              );
            })}
          </div>
          <div ref={loadRef}>{hasMore ? <Loader /> : null}</div>

          {showTaboola && (
            <TaboolaWidget
              mode="thumbnails-d"
              container={taboolaContainer}
              placement={taboolaPlacement}
              utm={utm}
              className={hasMore ? "d-none" : ""}
            />
          )}

          {showOutbrain && (
            <OutbrainWidget
              obWidgetID={outbrainID}
              utm={utm}
              obLink={pageURL}
              className={hasMore ? "d-none" : ""}
            />
          )}

          {pageReady && !isFacebookTemplate && (
            <div className="code-block code-block--footer">
              <div className="divider">
                <span>Advertisement</span>
              </div>
              <div id="StickyBanner" className="ad-container"></div>
            </div>
          )}

          {pageReady && isFacebookTemplate && (
            <div
              className="code-block code-block--footer"
              style={{ display: scrolledToFirstSection ? "block" : "none" }}
            >
              <div className="divider">
                <span>Advertisement</span>
              </div>
              <div id="StickyBanner" className="ad-container"></div>
            </div>
          )}
        </div>

        {pageReady && !isFacebookTemplate && (
          <aside className="post-ads-sidebar col-1">
            <div className="post-ads-sidebar__inner">
              <div className="divider">
                <span>Advertisement</span>
              </div>
              <div
                id="LeftColumn"
                className="ad-container ad-container--sidebar"
              ></div>
            </div>
          </aside>
        )}

        {pageReady && !isFacebookTemplate && (
          <aside className="post-ads-sidebar col-2">
            <div className="post-ads-sidebar__inner">
              <div className="divider">
                <span>Advertisement</span>
              </div>
              <div
                id="RightColumn"
                className="ad-container ad-container--sidebar"
              ></div>
            </div>
          </aside>
        )}
      </article>
    </>
  );
}

const BlogPosts = ({ data, pageContext }) => {
  const avaiableLangs = [pageContext.locale];

  return (
    <IntlProvider locale={pageContext.locale} messages={pageContext.messages}>
      <LocalesContext.Provider
        value={{
          slug: pageContext.slug,
          avaiableLangs,
          pageNumber: 0,
        }}
      >
        <Layout containerClasses="container--left" type="post">
          <BlogPost data={data} pageContext={pageContext} />
        </Layout>
      </LocalesContext.Provider>
    </IntlProvider>
  );
};

export default BlogPosts;

export const query = graphql`
  query ($slug: String!) {
    avaiablePostTranslations: allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        language {
          slug
        }
      }
    }
    posts: allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        id
        title
        nextPageTC
        slug
        excerpt
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            link
            height
            width
          }
        }
        modified(formatString: "MMMM D, YYYY")
        date(formatString: "MMMM D, YYYY")
      }
    }
  }
`;
